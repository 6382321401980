import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let regexAlphaNumeric= "^[a-zA-Z0-9 ]+$"
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPACE
  )
  // let errMsgAlphaNumeric= "Please enter Alpha Numeric with Space"
  let faxParrter = "^[0-9\\-\\+\\(\\)\\ ]+$"
  let errFaxPattern = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_FAX
  )
  // let errFaxPattern="Please enter valid Fax"
  let regexAlphaNumericCarrier = ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumericCarrier = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // let errMsgAlphaNumericCarrier= "Please enter alpha numeric values other than <,>,*,~"
  let phonePattern="^[0-9 -]*$"
  let phoneErrorMessage = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_NUMERIC_HYPHEN_SPACE
  )
  // let phoneErrorMessage="Please enter valid phone number[digits with space or -]"


  return Yup.object({
    partyName: Yup.string().required(requiredMessage).matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(60,"Max length allowed is 60"),
    partyType: Yup.string().required(requiredMessage).nullable(),
    partyIdentifierCode: Yup.string().nullable()
	.when('partyType', {
	  is: (value) => (value === 'LW' || value === 'EX' || value === 'SO'),
	  then: Yup.string().required(requiredMessage).nullable(),
	}).nullable(),
	partyIdentifierNumber: Yup.string().matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(15,"Max length allowed is 15").nullable()
	.when('partyType', {
	  is: (value) => (value === 'LW' || value === 'EX' || value === 'SO'),
	  then: Yup.string().required(requiredMessage).matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(15,"Max length allowed is 15").nullable(),
	}).nullable(),
    partyScacCode: Yup.string().matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(4,"Max length allowed is 4").nullable(),
    partyAddress: Yup.string().required(requiredMessage).matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(110,"Max length allowed is 110"),
    partyCity: Yup.string().matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(30,"Max length allowed is 30").nullable(),
    partyState: Yup.string().nullable(),
    partyCountry: Yup.string().nullable(),
    partyPostalCode: Yup.string().nullable().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(9,"Max length allowed is 9"),
    partyLocalContactName: Yup.string().nullable().matches(regexAlphaNumericCarrier,errMsgAlphaNumericCarrier).max(30,"Max length allowed is 30"),
    partyTelephoneNumber: Yup.string().nullable().matches(phonePattern,phoneErrorMessage).max(30,"Max length allowed is 30"),
    partyFax: Yup.string().nullable().matches(faxParrter,errFaxPattern).max(35,"Max length allowed is 35"),
    dba: Yup.string().max(50, "Max length allowed is 50").matches(regexAlphaNumericCarrier, errMsgAlphaNumericCarrier).nullable(),
    partyEmail: Yup.string().nullable().max(50,"Max length allowed is 50").email("Invalid email").matches("[A-Za-z0-9+_.-]+@(.+)$", "Enter Email in valid pattern"),
  })
}

export default makeValidationSchema
